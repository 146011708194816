export default {
  name: "LoanInstallmentPaymentHistory",
  data() {
    return {
      property: {
        animation: {
          loanInstallmentPaymentHistory: {
            isLoading: false,
          },
        },
        listElement: {
          loanInstallmentPaymentHistory: {
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        dateFrom: "",
        dateTo: "",
        loanAccountNumber: "",
        office: "",
      },
      options: {
        office: [],
      },
      table: {
        data: {
          loanInstallmentPaymentHistory: [],
        },
      },
    };
  },
  methods: {
    async getReportLoanInstallmentPaymentHistory() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.loanInstallmentPaymentHistory.isLoading = true;
      this.property.listElement.loanInstallmentPaymentHistory.downloading = true;
      this.table.data.loanInstallmentPaymentHistory = [];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/payment-history",
          payload: {
            loanAccountNumber: this.dataForm.loanAccountNumber,
            officeCode: this.dataForm.office,
            dateFrom: this.dataForm.dateFrom,
            dateTo: this.dataForm.dateTo,
          },
        });
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.table.data.loanInstallmentPaymentHistory = resp.data.data;
          }, 1000);
        } else {
          this.property.listElement.loanInstallmentPaymentHistory.message =
            resp.data.message;
        }
      } catch (error) {
        this.table.data.loanInstallmentPaymentHistory = [];
        this.property.listElement.loanInstallmentPaymentHistory.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      } finally {
        setTimeout(() => {
          this.property.animation.loanInstallmentPaymentHistory.isLoading = false;
          this.property.listElement.loanInstallmentPaymentHistory.downloading = false;
        }, 1000);
      }
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeCode;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.property.listElement.loanInstallmentPaymentHistory.message =
      "DATA NOT FOUND";
    this.getReferenceOffice();
  },
};
